import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Fade from 'react-reveal/Fade'
import { screen } from '../../../utils/'

const Title = styled.h1`
  ${tw`
  font-light
  leading-none
  mb-1
  text-4xl
  md:text-40px
  text-white
  mb-0
  text-center
`}
  font-family: 'Helvetica Lite Condensed', sans-serif;
  font-size: 5.0rem;

  strong {
    font-sizeL 5.0rem;
    font-family: 'Helvetica Bold Condensed', sans-serif;
    font-weight: 800;
  }

  p {
  ${tw`
    text-3xl
    md:text-5-5xl
    block
    sm:inline
  `}
  }
`

const taglineClasses = `
  font-sans
  font-light
  text-grey
  leading-normal
  text-md
  md:text-lg
  mt-1
  tracking-tight
  text-center
`

const Mantel = styled.div`
  ${tw`
    relative
    z-20
    pb-0
  `}
`

const mantelClasses = `
  relative
  z-20
  w-full
`

const MantelContainer = styled.div`
  margin-left: -24px;
  margin-right: -24px;
  ${tw`
    relative 
    -m-6 
    p-3 
    xl:m-0
    pr-0
`}
`

const HeadingContainer = styled.div`
  padding: 30px 0px 30px 12px;
  ${tw`
    relative
    -ml-3
    md:mt-8
  `}
  max-width: 95%;

  ${screen('xl')} {
    ${tw`
    max-w-full
    pl-5
    pr-6
    mt-0
    -ml-3
    `}
  }

  
}
`

const VideoContainer = styled.video`
  ${tw`
    h-full
    w-full
    absolute
`}
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: fill;

  ${screen('md')} {
    ${tw`
      cover
    `}
  }
`

const MantelHome = ({ title, description }) => (
  <MantelContainer>
    <VideoContainer
      playsInline
      autoPlay
      muted
      loop
      src="/assets/wave.mp4"
      type="video/mp4"
    ></VideoContainer>
    <div className="w-full mx-auto max-w-5xl xl:px-0 xl:flex items-center">
      <Mantel className={mantelClasses}>
        <Fade left>
          <HeadingContainer>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <p className={taglineClasses}>{description}</p>
          </HeadingContainer>
        </Fade>
      </Mantel>
    </div>
  </MantelContainer>
)

export default MantelHome
