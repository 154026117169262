import React from 'react'
import styled from 'styled-components'
import { screen } from '../../../utils/index'

// components
import SectionContainer from '../SectionContainer'
import SmartLink from '../SmartLink'

// assets
import bg from '../../../images/new-home-social-bg.svg'

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 50px;

  ${screen('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${screen('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const secondaryTitleClasses = `
  font-sans
  font-extrabold
  leading-none
  text-35px
  mb-2
  z-10
  lg:mb-4
`

const socialClasses = `
  flex
  items-center
  bg-white
  p-3
  card-shadow
  relative
`

const Bg = styled.div`
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: -150px;
    bottom: -150px;
    left: 0;
    right: 0;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
  }
`

const Social = ({ img, title, content, url }) => {
  return (
    <div className={socialClasses}>
      <img width="62" height="62" src={img} alt="Twitter" />
      <div className="ml-3">
        <h4 className="text-xl font-sans font-bold leading-none mb-1">
          {title}
        </h4>
        <p className="font-sans text-normal text-sm text-green-3">{content}</p>
      </div>
      <SmartLink to={url} className="absolute pin"></SmartLink>
    </div>
  )
}

const SocialGrid = ({ title, items }) => {
  return (
    <SectionContainer>
      <Bg>
        <h3 className={secondaryTitleClasses}>{title}</h3>
        <GridWrap className="relative z-10">
          {items.map((soc) => (
            <Social
              img={soc.icon ? soc.icon.url : null}
              title={soc.name ? soc.name.text : null}
              content={soc.link_text ? soc.link_text.text : null}
              url={soc.link ? soc.link.url : null}
            ></Social>
          ))}
        </GridWrap>
      </Bg>
    </SectionContainer>
  )
}

export default SocialGrid
