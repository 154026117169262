import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Fade from 'react-reveal/Fade'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import MantelHome from '../../General/MantelHome'
import GradientTop from '../../General/Cards/GradientTop'
import ThreeUp from '../../Layouts/ThreeUp'
import SectionContainer from '../../General/SectionContainer'
import ContentWButtons from '../../General/ContentWButtons'
import Button from '../../General/Buttons/Button'
import GradientCardGroup from '../../General/GradientCardGroup'
import SocialGrid from '../../General/SocialGrid'
import GradientButton from '../../General/Buttons/GradientButton'
import FutureFiVideoBanner from '../../General/FutureFiVideoBanner'
import SmartLink from '../../General/SmartLink'

// assets

import homeBGLines from '../../../images/pageAssets/background-lines-new.svg'

const backgrounds = {
  desktop: null,
  mobile: null,
  mobile2x: null,
}

const secondaryTitleClasses = `
  font-sans
  font-extrabold
  leading-none
  text-4xl
  mb-2
  lg:text-39px
  lg:mb-4
`

const HomeContainer = styled.div`
  background-image: url(${homeBGLines});
  ${tw`
    bg-center
    bg-cover
  `}
`

const GradLine = styled.div`
  height: 4px;
`

const GradientBar = () => (
  <GradLine className="purple-to-orange-vertical"></GradLine>
)

const renderComponent = (component, mantelData) => {
  const { primary, items } = component

  switch (component.slice_type) {
    case 'metrics_mantel': {
      return (
        <MantelHome
          {...mantelData}
          title={
            primary && primary.title && primary.title
              ? primary.title.text
              : null
          }
          description={
            primary && primary.description && primary.description
              ? primary.description.text
              : null
          }
          secondaryTitle={
            primary && primary.secondary_title && primary.secondary_title
              ? primary.secondary_title.text
              : null
          }
          buttonLink={
            primary && primary.button_link ? primary.button_link.url : null
          }
          buttonText={
            primary && primary.button_text && primary.button_text
              ? primary.button_text.text
              : null
          }
        ></MantelHome>
      )
    }
    case 'socialcards': {
      return (
        <Fade>
          <div className="my-6 md:my-8 lg:my-10">
            <SocialGrid
              title={
                primary && primary.title && primary.title
                  ? primary.title.text
                  : null
              }
              items={items || []}
            />
          </div>
        </Fade>
      )
    }
    case 'three_up_cards': {
      const cards =
        items && items.length > 0
          ? items.map((el) => ({
              image: {
                src: el.image ? el.image.url : null,
                alt: el.title ? el.title.text : null,
              },
              title: el.title ? el.title.text : null,
              description: el.description ? el.description.text : null,
              button: {
                external: false,
                route: el.link_button ? el.link_button.url : null,
                text: el.button_text ? el.button_text.text : null,
              },
            }))
          : null

      return (
        <Fade>
          <div className=" my-6 md:my-8 lg:my-10">
            <SectionContainer>
              <h3 className={secondaryTitleClasses}>
                {primary.title ? primary.title.text : null}
              </h3>
              <p className="font-sans font-light text-lg leading-normal mb-3 lg:text-lg lg:mb-5">
                {primary.description ? primary.description.text : null}
              </p>
              {cards && cards.length > 0 ? (
                <ThreeUp>
                  {cards.map((card) => (
                    <GradientTop {...card} />
                  ))}
                </ThreeUp>
              ) : null}
            </SectionContainer>
          </div>
        </Fade>
      )
    }
    case 'two_buttons': {
      const buttonLeft = items && items.length > 0 ? items[0] : null
      const buttonRight = items && items.length >= 1 ? items[1] : null

      return (
        <Fade>
          <div className=" pt-3 my-6 md:my-8 lg:my-10">
            <SectionContainer>
              <div className="flex flex-col lg:flex-row items-center">
                <div className="w-full lg:w-1/2 lg:mr-4 lg:mb-0 mb-6">
                  <h3 className="mb-2 font-sans font-bold text-2xl lg:text-left text-center">
                    {buttonLeft ? buttonLeft.title.text : null}
                  </h3>
                  <div className="lg:text-left text-center">
                    <SmartLink
                      to={buttonLeft ? buttonLeft.button_link.url : null}
                    >
                      <GradientButton>
                        {buttonLeft ? buttonLeft.button_text.text : null}
                      </GradientButton>
                    </SmartLink>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 lg:ml-4 ">
                  <h3 className="mb-2 font-sans font-bold text-2xl lg:text-left text-center">
                    {buttonRight ? buttonRight.title.text : null}
                  </h3>
                  <div className="lg:text-left text-center">
                    <SmartLink
                      to={buttonRight ? buttonRight.button_link.url : null}
                    >
                      <GradientButton>
                        {buttonRight ? buttonRight.button_text.text : null}
                      </GradientButton>
                    </SmartLink>
                  </div>
                </div>
              </div>
            </SectionContainer>
          </div>
        </Fade>
      )
    }
    case 'full_image_with_title_and_link': {
      return (
        <Fade>
          <SectionContainer>
            <h3 className={secondaryTitleClasses}>
              {component.primary.title.text}
            </h3>
            <GradientBar />
            <img src={component.primary.image.url} alt="company logos" />
            <div className="mt-2">
              <SmartLink
                to={component.primary.button_link.url}
                className="no-underline"
              >
                <Button>{component.primary.button_text.text}</Button>
              </SmartLink>
            </div>
          </SectionContainer>
        </Fade>
      )
    }
    case 'gradient_cards': {
      return (
        <Fade>
          <div className="my-6 md:my-8 lg:my-10">
            <GradientCardGroup cards={items}></GradientCardGroup>
          </div>
        </Fade>
      )
    }
    case 'two_columns_with_links': {
      return (
        <Fade>
          <div className="my-6 md:my-8 lg:my-10">
            <ContentWButtons data={component} />
          </div>
        </Fade>
      )
    }
    case 'horizontalBlocks': {
      const { items } = component
      const { title } = component.primary

      return (
        <Fade>
          <div className="relative z-20 my-6 md:my-8 lg:my-10">
            <SectionContainer>
              <h3 className={secondaryTitleClasses}>{title.text}</h3>
              {items.map((item, index) =>
                index % 2 === 0 ? (
                  <div className="my-3 lg:flex lg:items-center lg:my-6">
                    <img
                      className="mb-2 lg:mb-0 lg:w-1/2"
                      src={item.image.url}
                      alt={item.subtitle.text.text}
                    />
                    <div className="lg:px-4">
                      <h4 className="font-sans font-bold mb-2 text-23px lg:mb-3">
                        {item.subtitle.text.text}
                      </h4>
                      <p className="font-sans font-light text-lg leading-normal mb-3 lg:text-lg lg:mb-5">
                        {item.description.text.text}
                      </p>
                      <SmartLink
                        to={item.button_link.url}
                        className="no-underline"
                      >
                        <Button>{item.button_text.text.text}</Button>
                      </SmartLink>
                    </div>
                  </div>
                ) : (
                  <div className="my-3 lg:flex lg:flex-row-reverse lg:items-center lg:my-6">
                    <img
                      className="mb-2 lg:mb-0 lg:w-1/2"
                      src={item.image.url}
                      alt={item.subtitle.text.text}
                    />
                    <div className="lg:pr-4">
                      <h4 className="font-sans font-bold mb-2 text-2xl lg:mb-3">
                        {item.subtitle.text.text}
                      </h4>
                      <p className="font-sans font-light text-lg leading-normal mb-3 lg:text-lg lg:mb-5">
                        {item.description.text.text}
                      </p>
                      <SmartLink
                        to={item.button_link.url}
                        className="no-underline"
                      >
                        <Button>{item.button_text.text.text}</Button>
                      </SmartLink>
                    </div>
                  </div>
                )
              )}
            </SectionContainer>
          </div>
        </Fade>
      )
    }
    case 'video_banner': {
      const items = component.items
        ? component.items.map((button) => ({
            icon: button.icon.url,
            button: {
              url: button.link.url,
              text: button.text.text,
            },
            text: button.text.text,
            column: button.column,
          }))
        : []

      return (
        <Fade>
          <div className="relative z-10 -mx-6 xl:mx-0">
            <FutureFiVideoBanner
              loopingVideo={{ src: component.primary.video.url }}
              buttonListItems={items.filter((el) => el.column === 'Left')}
              featureList={{
                items: items.filter((el) => el.column !== 'Left'),
                heading: component.primary.title.text,
              }}
            />
          </div>
        </Fade>
      )
    }
    default:
      return null
  }
}

const HomePageV2 = ({ seo, mantelData, data }) => {
  if (!data || !data.body) return null
  const components = data.body

  return (
    <PageWrap seo={seo} backgrounds={backgrounds} bg={false}>
      <HomeContainer>
        {components && components.length > 0
          ? components.map((el, index) => {
              if (el.slice_type === 'metrics_mantel') {
                return (
                  <div key={`component_${index}`}>
                    {renderComponent(el, mantelData)}
                  </div>
                )
              }
              return <div key={`component_${index}`}>{renderComponent(el)}</div>
            })
          : null}
      </HomeContainer>
    </PageWrap>
  )
}

export default HomePageV2
