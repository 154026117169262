import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen, rem } from '../../../utils'

// components
import Button from '../Buttons/Button'
import SmartLink from '../SmartLink'
import PrimaryHeading from '../Headings/PrimaryHeading'
import TertiaryHeading from '../Headings/TertiaryHeading'

// assets
import accentStripe from '../../../images/pageAssets/accent-stripe.png'
import moduleArrowGradient from '../../../images/pageAssets/module-arrow-gradient.svg'
import moduleArrowGradientDown from '../../../images/pageAssets/module-arrow-gradient-down.svg'
import videoOverlayMask from '../../../images/pageAssets/video-overlay-mask.png'

const FutureFiVideoBannerWrap = styled.div`
  ${tw`
    relative
    px-0
    mx-0
    bg-right
    bg-no-repeat
    overflow-hidden
  `}

  ::before {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 70%;
    bottom: 0;
    background-color: #e7e7e8;
  }

  background-color: white;

  ${screen('2xl')} {
    ::before {
      display: block;
    }

    // background-color: #f9f9f9;
    background-image: url(${accentStripe});
  }
`

const VideoOverlayMask = styled.div`
  ${tw`
    bg-cover
    bg-right
    w-full
    h-full
    absolute
    z-20
  `}

  background-image: url(${videoOverlayMask})
`

const ButtonListWrap = styled.div`
  ${tw`
    bg-bottom
    bg-cover
    bg-no-repeat
    w-full
    flex
    flex-col
    justify-center
    self-stretch
    py-4
    px-4
    md:w-1/2
    md:mt-0
    md:px-4
    lg:w-44
    xl:px-0
    xl:pr-8
  `}

  background-image: url(${moduleArrowGradientDown});

  ${screen('md')} {
    ${tw`
      bg-right
    `}
    background-image: url(${moduleArrowGradient});

    button {
      max-width: ${rem(260)};
    }
  }
`

const InnerContainer = styled.div`
  ${tw`
    mx-auto
  `}

  max-width: ${rem(1150)};
`

const VideoContainer = styled.div`
  width: ${rem(620)};
  height: ${rem(411)};
  margin-left: -${rem(310)};
`

const Content = styled.div`
  ${screen('xl')} {
    width: calc(100% - ${rem(310)});
  }
`

const FutureFiVideoBanner = ({
  loopingVideo,
  buttonListItems,
  featureList,
}) => (
  <FutureFiVideoBannerWrap>
    <InnerContainer className="xl:flex">
      <VideoContainer className="relative overflow-hidden hidden xl:flex xl:self-stretch">
        <VideoOverlayMask />
        <video
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
          style={{ 'object-fit': 'cover' }}
          className="relative z-10 w-full h-full block"
        >
          <source src={loopingVideo.src} type="video/mp4" />
        </video>
      </VideoContainer>
      <Content className="flex flex-col  md:flex-row items-stretch">
        {buttonListItems && (
          <ButtonListWrap>
            {buttonListItems.map((item) => (
              <div className="flex items-center mt-3" key={item.button.text}>
                <div className="flex-no-shrink w-16 mr-2">
                  <img src={item.icon} />
                </div>
                <SmartLink className="no-underline w-full" to={item.button.url}>
                  <Button arrowFlushRight className="w-full text-left">
                    {item.button.text}
                  </Button>
                </SmartLink>
              </div>
            ))}
          </ButtonListWrap>
        )}
        {featureList.items && (
          <div className="py-4 w-full px-3 md:px-6 md:w-1/2 md:px-5 lg:w-56">
            <div class="mb-3">
              <PrimaryHeading>{featureList.heading}</PrimaryHeading>
            </div>
            {featureList.items.map((item, i) => (
              <div className="flex items-center mb-4 " key={item.text}>
                <div className="flex-no-shrink w-16 mr-2">
                  <img
                    className="h-auto"
                    style={{ width: '55px' }}
                    src={item.icon}
                  />
                </div>
                <h3 className="font-sans font-normal text-lg lg:text-xl text-charcoal">
                  {item.text}
                </h3>
              </div>
            ))}
          </div>
        )}
      </Content>
    </InnerContainer>
  </FutureFiVideoBannerWrap>
)

export default FutureFiVideoBanner
