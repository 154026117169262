import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { extract } from '../utils/graphql'
import mergePreviewData from '../utils/mergePreviewData'
import HomePageV2 from '../components/Pages/Home/HomePageV2'

const HomePage = ({ data, pageContext }) => {
  return (
    <HomePageV2></HomePageV2>
  )
}

export default HomePage
