import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import classnames from 'classnames'

// components
import Button from '../Buttons/Button'
import { Link } from 'gatsby'

const Bar = styled.div`
  height: 8px;
`

const Description = styled.div`
  ${tw`
    mt-3
    font-light
    font-sans
    leading-normal
    text-base
  `}

  p:not(:last-child),
  img:not(:last-child) {
    ${tw`
      mb-3
    `}
  }

  a {
    ${tw`
      text-green-3
    `}
  }
`

const authorClasses = `
  my-3
  font-normal
  font-sans
  leading-normal
  text-base
`

const someClasses = 'px-2 pb-6 lg:px-4 lg:pb-8 s'

const GradientTop = ({
  image,
  icon,
  title,
  author,
  authorPrefix,
  description,
  button,
  hideBar,
  date,
  charactersLimit,
  darkMode = false,
}) => {
  const LinkComponent = button ? (button.external ? 'a' : Link) : null

  const truncateText = () => {
    if (charactersLimit) {
      let truncatedText = `${description}`.substr(0, charactersLimit).trim()

      if (truncatedText.endsWith('.')) {
        truncatedText = `${truncatedText}..`
      } else {
        truncatedText = `${truncatedText}...`
      }

      return truncatedText
    }

    return description
  }

  return (
    <div
      className={classnames(
        'gradient-top-card',
        'border',
        'simple-fade-in',
        'h-full relative',
        {
          'bg-white shadow-blur hover:shadow-lg border-light-grey-2 hover:border-grey': !darkMode,
          'bg-black border-grey mt-4': darkMode,
        }
      )}
    >
      {!hideBar && (
        <Bar
          className="purple-to-orange-horizontal"
          style={
            darkMode
              ? {
                  top: '-30px',
                  position: 'relative',
                  marginLeft: '-1px',
                  marginRight: '-1px',
                }
              : null
          }
        ></Bar>
      )}
      {image && image.src && (
        <div className="p-2 lg:p-4">
          <img src={image.src} alt={image.alt} />
        </div>
      )}
      <div
        className={classnames(someClasses, {
          'pt-4 md:pt-6': !image || (image && !image.src),
        })}
      >
        <div
          className={classnames(
            'pb-2',
            'text-green-3',
            'font-sans',
            'text-xs',
            'font-extrabold'
          )}
        >
          {date ? <p>{date} </p> : null}
        </div>
        <div className="flex items-center">
          {icon && (
            <span
              className="mr-1 lg:mr-2"
              dangerouslySetInnerHTML={{ __html: icon }}
            ></span>
          )}
          <h4
            className={classnames(
              'font-sans',
              'font-bold',
              'leading-tight',
              'text-xl',
              'lg:text-2xl',
              {
                'text-black': !darkMode,
                'text-white': darkMode,
              }
            )}
          >
            {title}
          </h4>
        </div>
        {author && (
          <p className={authorClasses}>
            {authorPrefix}
            <span className="text-green-3 text-base font-bold"> {author}</span>
          </p>
        )}
        <Description
          className={`mb-6 lg:mb-12 text-base ${
            darkMode ? 'text-grey' : 'text-black'
          }`}
          dangerouslySetInnerHTML={{
            __html: truncateText(),
          }}
        />
        {button && (
          <div
            className={classnames(
              'absolute',
              'pb-4',
              'px-2',
              'lg:px-4',
              'lg:pb-6',
              'pin-b',
              'pin-x'
            )}
          >
            <LinkComponent
              className="no-underline"
              to={button.route}
              href={button.route}
              target={button.external ? '_blank' : null}
            >
              <Button transparent={darkMode}>{button.text}</Button>
            </LinkComponent>
          </div>
        )}
      </div>
    </div>
  )
}

export default GradientTop
