import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

// components
import SectionContainer from '../SectionContainer'
import Button from '../Buttons/Button'
import SmartLink from '../SmartLink'

const titleClasses = `
  
    font-helvetica-medium
    leading-none
    mb-2
    text-4xl
    lg:mb-4
    lg:text-4-5xl
    
`

const Content = styled.div`
  ${tw`
    text-lg
    font-light
    font-sans
    leading-normal
  `}

  p {
    ${tw`
      mb-1
    `}
  }

  ul {
    ${tw`
      mb-1
      ml-3
    `}
  }

  a {
    ${tw`
      text-green-3
      no-underline
  `}
  }
`

const contentClasses = `
  font-sans
  font-normal
  leading-relaxed
  text-base
  lg:text-lg
`

const ContentWButtons = ({ data, darkMode = false }) => {
  if (!data || !data.primary) return null
  const { text_left, text_right, title } = data.primary
  const { items } = data

  return (
    <SectionContainer>
      <div className="relative z-10">
        <h2
          className={`${titleClasses} ${
            darkMode ? 'text-white' : 'text-black'
          }`}
        >
          {title ? title.text : ''}
        </h2>
        <div className="lg:flex mb-3">
          <div className="lg:w-3/5 mb-4 lg:mb-0 lg:pr-6">
            <Content
              className={`${contentClasses} ${
                darkMode ? 'text-grey' : 'text-dark-grey-2'
              }`}
              dangerouslySetInnerHTML={{
                __html: text_left ? text_left.text : null,
              }}
            />
          </div>
          <div className="lg:w-2/5">
            <Content
              className={`${contentClasses} ${
                darkMode ? 'text-grey' : 'text-dark-grey-2'
              }`}
              dangerouslySetInnerHTML={{
                __html: text_right ? text_right.html : null,
              }}
            />
          </div>
        </div>
        <div className="lg:flex">
          {items.map((button, index) => (
            <SmartLink
              key={`button_${index}`}
              to={button.button_link ? button.button_link.url : ''}
              className={`lg:w-1/3 no-underline ${
                index === items.length - 1 ? '' : 'mr-2'
              }`}
            >
              <Button arrowFlushRight className="w-full" transparent={darkMode}>
                {button.button_text ? button.button_text.text : null}
              </Button>
            </SmartLink>
          ))}
        </div>
      </div>
    </SectionContainer>
  )
}

export default ContentWButtons
