import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { rem, screen } from '../../../utils'

// components
import SectionContainer from '../SectionContainer'
import Button from '../Buttons/Button'
import SmartLink from '../SmartLink'

// assets
import newsBg from '../../../images/new-home-news-bg.jpg'
import bg from '../../../images/home-bg-three-cards.png'

const Card = styled.div`
  ${tw`
    pb-10
  `}
`

const cardClasses = `
  relative
  font-sans
  text-black
  lg:text-white
  bg-cover
  pt-4
  lg:pt-8
  px-0
  md:px-1
  pb-2
  md:pb-3
  mb-1
  md:mb-2
  lg:mx-1
  lg:w-1/3
`

const ButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: ${rem(32)};
  width: 100%;

  ${screen('lg')} {
    bottom: ${rem(48)};
  }
`

const Bg = styled.div`
  ${screen('lg')} {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 24px;
  }
`

const GradientCardGroup = ({ cards }) => (
  <Bg>
    <SectionContainer>
      <div className="lg:flex lg:justify-between lg:-mx-4">
        {cards.map((card, index) => (
          <Card bg={newsBg} className={cardClasses} key={`card_${index}`}>
            <img
              className=" mb-3 w-full"
              src={card.image ? card.image.url : null}
              alt=""
            />
            <h4 className="font-bold leading-none pb-2 lg:pb-3 text-3xl lg:text-4-5xl">
              {card.title ? card.title.text : null}
            </h4>
            <p className="font-light text-base lg:text-lg leading-normal pb-0 lg:pb-4">
              {card.description ? card.description.text : null}
            </p>
            <ButtonContainer className="px-1">
              <SmartLink
                to={card.button_link ? card.button_link.url : null}
                className="inline-block no-underline"
              >
                <Button>
                  {card.button_text ? card.button_text.text : null}
                </Button>
              </SmartLink>
            </ButtonContainer>
          </Card>
        ))}
      </div>
    </SectionContainer>
  </Bg>
)

export default GradientCardGroup
